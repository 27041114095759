import GATSBY_COMPILED_MDX from "/Users/averysmith/Repos/melalogic-m3/src/posts/resources/hair-breakage.mdx";
import {graphql, Link} from "gatsby";
import {Banner, CareItem, Headline, InfoItem, Metadata, Obl, Pin} from "atoms";
import {CareInstructions, EmailForm, InfoNugget, ResourceNav} from "molecules";
import {Layout, ResourcePanel} from "organisms";
import {MDXProvider} from "@mdx-js/react";
import React, {useEffect, useState} from "react";
import * as styles from "./resource.module.scss";
import classNames from "classnames";
const ResourceTemplate = ({data, children, location}) => {
  const {buyPrintLink, description, image, keywords, slug, title} = data.mdx.frontmatter;
  const resouceNavItems = [{
    icon: "overview",
    label: "Overview",
    to: `/${slug}/#overview`
  }, {
    icon: "aid",
    label: "Care Instructions",
    to: `/${slug}/#care-instructions`
  }, {
    icon: "shop",
    label: "Buy Print",
    to: buyPrintLink
  }];
  const [currentTarget, setCurrentTarget] = useState(null);
  useEffect(() => {
    window.updateResourceNavTab = scrolledSection => {
      setCurrentTarget(`/${slug}/#${scrolledSection}`);
    };
  }, [slug]);
  const shortcodes = {
    CareInstructions,
    CareItem,
    Headline,
    InfoNugget,
    InfoItem,
    Link,
    Pin,
    Obl,
    ResourcePanel
  };
  const preSelectedSkinCondition = location.pathname.endsWith("/") ? location.pathname.slice(1, location.pathname.length - 1) : location.pathname.slice(1);
  const preSelectedSkinTone = location.search.split("t=")[1];
  return React.createElement("div", {
    "data-testid": "resource",
    className: styles.resource
  }, React.createElement(Metadata, {
    title: title,
    description: description,
    keywords: keywords,
    openGraphImageFilename: image,
    openGraphType: "article",
    openGraphUrl: location.pathname,
    script: [{
      src: `${process.env.GATSBY_ACTIVECAMPAIGN_FORM_URL}?id=${process.env.GATSBY_ACTIVECAMPAIGN_FORM_ID}`,
      type: "text/javascript",
      charset: "utf-8"
    }]
  }), React.createElement("article", null, React.createElement(Layout, {
    container: "container-fluid",
    preSelectedSkinCondition: preSelectedSkinCondition,
    preSelectedSkinTone: preSelectedSkinTone
  }, React.createElement("div", {
    className: "row center-sm"
  }, React.createElement("div", {
    className: classNames("col-sm-2 center-sm", styles.hideOnMobile)
  }, React.createElement(ResourceNav, {
    items: resouceNavItems,
    currentTarget: currentTarget
  })), React.createElement("div", {
    className: "col-sm-10 col-md-9"
  }, React.createElement("div", {
    className: styles.showOnMobile
  }, React.createElement(ResourceNav, {
    items: resouceNavItems,
    currentTarget: currentTarget
  })), React.createElement("div", {
    id: "overview"
  }, React.createElement(Banner, {
    title: title,
    theme: slug
  })), React.createElement(MDXProvider, {
    components: shortcodes
  }, children), React.createElement(EmailForm, {
    activeCampaignFormId: process.env.GATSBY_ACTIVECAMPAIGN_FORM_ID
  }))))));
};
ResourceTemplate
export default function GatsbyMDXWrapper(props) {
  return React.createElement(ResourceTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        buyPrintLink
        date
        description
        image
        keywords
        slug
        title
      }
    }
  }
`;
