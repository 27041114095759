/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getScrollTriggers, setScrollTrigger} from "utils/scrolltriggers";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    ul: "ul",
    li: "li",
    em: "em"
  }, _provideComponents(), props.components), {ResourcePanel, Pin, InfoNugget, Headline, InfoItem, CareInstructions, CareItem, Obl} = _components;
  if (!CareInstructions) _missingMdxReference("CareInstructions", true);
  if (!CareItem) _missingMdxReference("CareItem", true);
  if (!Headline) _missingMdxReference("Headline", true);
  if (!InfoItem) _missingMdxReference("InfoItem", true);
  if (!InfoNugget) _missingMdxReference("InfoNugget", true);
  if (!Obl) _missingMdxReference("Obl", true);
  if (!Pin) _missingMdxReference("Pin", true);
  if (!ResourcePanel) _missingMdxReference("ResourcePanel", true);
  return React.createElement(React.Fragment, null, React.createElement(ResourcePanel, {
    id: "figure01",
    image: "figure01",
    imageAlt: "lorem ipsum",
    roundTopCorners: true,
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers
  }, React.createElement(Pin, {
    id: "figure01",
    type: "start",
    height: 200,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Textured or highly curled hair has different needs than straight hair."), React.createElement(_components.p, null, "It is important to establish a well-rounded hair care routine that accounts for the specific needs of your hair texture.")), React.createElement(Pin, {
    id: "figure01",
    type: "end",
    height: 200,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure02",
    image: "figure02",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers,
    slug: props.data.mdx.frontmatter.slug
  }, React.createElement(Pin, {
    id: "figure02",
    type: "start",
    height: 300,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, React.createElement(_components.strong, null, "Trichorrhexis nodosa (hair breakage)"), " is a very common issue where weakened or thickened parts of the hair shaft (nodes) form."), React.createElement(_components.p, null, "Once the hair shaft is damaged, the damage is permanent.")), React.createElement(Pin, {
    id: "figure02",
    type: "end",
    height: 400,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure03",
    image: "figure03",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers,
    slug: props.data.mdx.frontmatter.slug
  }, React.createElement(Pin, {
    id: "figure03",
    type: "start",
    height: 300,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(Headline, {
    level: 2
  }, "Causes"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, null, React.createElement(_components.strong, null, "Mechanical and chemical damage"), " can lead to hair breakage, but the ", React.createElement(_components.strong, null, "shape of the strands themselves"), " can make hair susceptible to dryness and breakage."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(InfoItem, {
    noBorder: true
  }, "Tightly curled strands can have ", React.createElement(_components.strong, null, "more points of friction with other strands"), ", and it can also be ", React.createElement(_components.strong, null, "difficult for sebum produced in the scalp to coat the entire strand"), " and keep the hair moisturized."), "\n"), "\n")), React.createElement(Pin, {
    id: "figure03",
    type: "end",
    height: 400,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(ResourcePanel, {
    id: "figure04",
    image: "figure04",
    imageAlt: "lorem ipsum",
    slug: props.data.mdx.frontmatter.slug,
    getTriggers: getScrollTriggers,
    slug: props.data.mdx.frontmatter.slug
  }, React.createElement(Pin, {
    id: "figure04",
    type: "start",
    height: 300,
    setTrigger: setScrollTrigger
  }), React.createElement(InfoNugget, null, React.createElement(_components.p, null, "Conditioning products ", React.createElement(_components.strong, null, "coat the hair, reduce friction, and can help limit further breakage"), "."), React.createElement(_components.p, null, "They can mimic the properties of sebum. However, conditioners and other products do not reverse trichorrhexis nodosa."), React.createElement(_components.p, null, React.createElement(_components.em, null, "Avoid washing the hair too frequently, as this can contribute to dryness"), ".")), React.createElement(Pin, {
    id: "figure04",
    type: "end",
    height: 400,
    setTrigger: setScrollTrigger
  })), "\n", React.createElement(CareInstructions, null, React.createElement(Headline, {
    level: 3
  }, "About Hair Products that Target Black People"), React.createElement(_components.p, null, "There are a number of hair care and styling products marketed to black consumers. Standing in the hair care aisle at the grocery store can be quite overwhelming."), React.createElement(_components.p, null, "These following are important to help establish a well-rounded hair care routine:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, React.createElement(_components.strong, null, React.createElement(Obl, {
    to: "https://amzn.to/3tPnagV",
    title: "Amazon Affiliate Link"
  }, "Shampoo")), ": consider a product that is sulfate free which is less likely to strip your hair of protective oils and reduce breakage"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, React.createElement(_components.strong, null, React.createElement(Obl, {
    to: "https://amzn.to/3VgB8E9",
    title: "Amazon Affiliate Link"
  }, "Rinse out conditioner")), ": it is important to condition you hair after every shampoo"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, React.createElement(_components.strong, null, React.createElement(Obl, {
    to: "https://amzn.to/3i4qDFN",
    title: "Amazon Affiliate Link"
  }, "Deep conditioner")), ": consider adding to your hair care regimen if you have dry or brittle hair that has difficulty staying moisturized"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(CareItem, {
    icon: "alert"
  }, React.createElement(_components.strong, null, React.createElement(Obl, {
    to: "https://amzn.to/3go5MwL",
    title: "Amazon Affiliate Link"
  }, "Leave in conditioner")), ": can be applied to the hair regularly, help reduce frizz and add moisture"), "\n"), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
